import axios from "axios";
import { bugsnag } from "../helper/bugsnag";
import { handleResponse, handleError } from "./response-handler";
import { GET, POST } from "../../constants/api";
import { ApiMethod, ApiResponse } from "../../../types/api";

const BASE_URL = process.env.REACT_APP_CL_API;

export const makeRequest = async (
  method: ApiMethod,
  url: string,
  data?: Object
) => {
  try {
    const response = await axios<ApiResponse>({
      baseURL: BASE_URL,
      method,
      url,
      data,
    });

    return handleResponse(response.data);
  } catch (error: any) {
    bugsnag.report("Error trying to post user code", {
      requestParams: { method, url, data },
      errors: error,
    });
    return handleError(error.response?.data ?? error);
  }
};

const get = (resource: string, id?: number | string) => {
  const url = `${BASE_URL}/${resource}/${id ? `${id}/` : ""}`;
  return makeRequest(GET, url);
};

const post = (resource: string, id?: number | string, payload?: {}) => {
  const url = `${BASE_URL}/${resource}/${id ? `${id}/` : ""}`;
  return makeRequest(POST, url, payload);
};

export const apiProvider = {
  makeRequest,
  get,
  post,
};
