import React from "react";
import * as ReactDOM from "react-dom";
import UserValidation from "../user-validation/UserValidation";

interface ModalPortalProps {
  isLoading: boolean;
  errors: string;
}

const ModalPortal = ({ isLoading, errors }: ModalPortalProps) => {
  return ReactDOM.createPortal(
    <UserValidation isLoading={isLoading} errors={errors} />,
    document.body
  );
};

export default ModalPortal;
