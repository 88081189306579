import Spinner from "../../ui/spinner/Spinner";
import "./UserValidation.css";

interface UserValidationProps {
  isLoading: boolean;
  errors: string;
}

const UserValidation = ({ isLoading, errors }: UserValidationProps) => {
  const sharecareDashUrl = process.env.REACT_APP_SC_DASH;

  const redirectToSharecare = () => {
    window.open(sharecareDashUrl, "_self");
  };

  return (
    <div
      id="modal"
      data-testid="modal-dialog"
      className="m__background"
      role="dialog"
      aria-labelledby="dialog-title"
      aria-describedby="dialog-description"
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <div
        className="m__body"
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
        }}
      >
        {isLoading ? (
          <Spinner />
        ) : errors ? (
          <div className="error__message" data-testid={"error-message"}>
            <p>{errors}</p>
            <div className="error__message__url">
              <p>Back to Sharecare:</p>
              <div onClick={redirectToSharecare} data-testid={"error-link"}>
                {sharecareDashUrl}
              </div>
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default UserValidation;
