import Bugsnag from "@bugsnag/js";

const config = () => {
  Bugsnag.start({
    apiKey: process.env.REACT_APP_BUGSNAG_KEY || "",
    releaseStage: process.env.NODE_ENV,
    enabledReleaseStages: ["production", "staging"],
    enabledErrorTypes: {
      unhandledRejections: false,
    },
    maxEvents: 80,
  });
};

const report = (title: string, params?: any) => {
  Bugsnag.notify(title, (event) => {
    event.addMetadata("sharecare-interstitial", params);
  });
};

export const bugsnag = {
  config,
  report,
};
