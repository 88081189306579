import { apiProvider } from "../utils/apis/api-provider";

const postUserCode = (code: string, offeringCode: string, sponsor: string) => {
  return apiProvider.post("v1/web/accounts/login-sharecare-sso", undefined, {
    auth_code: code,
    offering_code: offeringCode,
    sponsor: sponsor,
  });
};

export const user = {
  postUserCode,
};
