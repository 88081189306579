// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("/src/assets/images/icons/spinner100x100.gif", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("/src/assets/images/icons/spinner50x50.gif", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".spinner {\n  margin: 50px;\n  width: 100px;\n  height: 100px;\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n}\n@keyframes ckw {\n  0% {\n    transform: rotate(360deg);\n  }\n  100% {\n    transform: rotate(0deg);\n  }\n}\n@media (max-width: 767px) {\n  .spinner {\n    width: 50px;\n    height: 50px;\n    background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ");\n  }\n}\n", "",{"version":3,"sources":["webpack://./src/ui/spinner/Spinner.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,YAAY;EACZ,aAAa;EACb,yDAAoE;AACtE;AACA;EACE;IACE,yBAAyB;EAC3B;EACA;IACE,uBAAuB;EACzB;AACF;AACA;EACE;IACE,WAAW;IACX,YAAY;IACZ,yDAAkE;EACpE;AACF","sourcesContent":[".spinner {\n  margin: 50px;\n  width: 100px;\n  height: 100px;\n  background-image: url(\"/src/assets/images/icons/spinner100x100.gif\");\n}\n@keyframes ckw {\n  0% {\n    transform: rotate(360deg);\n  }\n  100% {\n    transform: rotate(0deg);\n  }\n}\n@media (max-width: 767px) {\n  .spinner {\n    width: 50px;\n    height: 50px;\n    background-image: url(\"/src/assets/images/icons/spinner50x50.gif\");\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
