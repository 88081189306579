import { ApiResponse, ResponseObject } from "../../../types/api";
import { SUCCESS, FAIL } from "../../constants/api";

export function handleResponse(response: ApiResponse): ResponseObject {
  if (response.status === SUCCESS && response.data) {
    return { data: response.data, errors: null };
  } else if (response.status === FAIL && response.message) {
    return { data: null, errors: response.message.error_message };
  } else {
    return { data: null, errors: "Error parsing data from server" };
  }
}

export function handleError(error: any): ResponseObject {
  if (error.status === FAIL && error.message) {
    return { data: null, errors: error.message.error_message };
  } else {
    return { data: null, errors: "Error fetching data from server" };
  }
}
