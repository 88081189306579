import { useEffect, useState } from "react";

export const useFamilyOnboardingScript = (enableScript: boolean) => {
  const urlScript = process.env.REACT_APP_FOM_BUNDLE;
  const urlStyles = process.env.REACT_APP_FOM_STYLES;

  const [status, setStatus] = useState<string>("disabled");
  const [counter, setCounter] = useState<number>(0);

  const verifyScriptLoaded = () => {
    setCounter((prev) => prev++);
    if (counter <= 5000)
      if (window.triggerOnboardingModal) setStatus("enabled");
      else setTimeout(verifyScriptLoaded, 0);
    else setStatus("error");
  };

  useEffect(() => {
    if (enableScript) {
      let modalScript: HTMLScriptElement | null = document.querySelector(
        `script[src="${urlScript}"]`
      );
      const handleScript = (e: { type: any }) => {
        if (e.type === "load") setTimeout(verifyScriptLoaded, 0);
        else setStatus("error");
      };

      if (!modalScript) {
        const modalCssScript = document.createElement("link");
        if (urlStyles) {
          modalCssScript.href = urlStyles;
          modalCssScript.rel = "stylesheet";
          document.body.appendChild(modalCssScript);
        }

        if (urlScript) {
          modalScript = document.createElement("script");
          modalScript.src = urlScript;
          modalScript.type = "application/javascript";
          document.body.appendChild(modalScript);
        }
      }
      modalScript?.addEventListener("load", handleScript);
      modalScript?.addEventListener("error", handleScript);

      return () => {
        modalScript?.removeEventListener("load", handleScript);
        modalScript?.removeEventListener("error", handleScript);
      };
    }
  }, [enableScript]);

  return status;
};
