import React, { useState, useEffect } from "react";

import { ModalParams } from "../../types/modal-onboarding";
import { RefCodeSchema } from "../../types/content-manager";

import { helperMethods } from "../../services/utils/helper/redirections";
import { contentManager } from "../../services/constants/content-manager";
import { bugsnag } from "../../services/utils/helper/bugsnag";

import { user } from "../../services/models/user";

import Hero from "../../components/hero/Hero";
import Header from "../../components/header/Header";
import ModalPortal from "../../components/modal/ModalPortal";

import { useFamilyOnboardingScript } from "../../hooks/useFamilyOnboardingScript";

const Home: React.FC = () => {
  const [errors, setErrors] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const [sponsor, setSponsor] = useState<string | undefined>(undefined);
  const [offeringCode, setOfferingCode] = useState<string | null>(null);
  const [authorizationCode, setAuthorizationCode] = useState<string | null>(
    null
  );
  const [contentData, setContentData] = useState<RefCodeSchema | null>(null);
  const [modalParams, setModalParams] = useState<ModalParams | null>(null);

  const [loadScript, setLoadedScript] = useState<boolean>(false);
  const statusScript = useFamilyOnboardingScript(loadScript);

  useEffect(() => {
    if (process.env.REACT_APP_ENV) {
      const windowUrl = window.location.search;
      const params = new URLSearchParams(windowUrl);
      if (params.get("sponsor"))
        setSponsor(params.get("sponsor")?.split("/").slice(-1)[0]);
      if (params.get("offeringCode"))
        setOfferingCode(params.get("offeringCode"));
      if (params.get("code")) setAuthorizationCode(params.get("code"));
    } else {
      setIsLoading(false);
      setErrors("Server detect an issue");

      bugsnag.report("missing REACT_APP_ENV variable");
    }
  }, []);

  useEffect(() => {
    const postCode = async () => {
      if (offeringCode && sponsor) {
        const content = contentManager[sponsor][offeringCode];
        setContentData(content);

        if (authorizationCode) {
          const response = await user.postUserCode(
            authorizationCode,
            offeringCode,
            sponsor
          );

          if (response.errors) {
            setErrors(response.errors);
            setIsLoading(false);
          } else {
            const data = response.data;
            if (data?.redirect_url) {
              helperMethods.redirectToCarelinxDashboard(data?.redirect_url);
            } else if (data?.account_data) {
              const accountData = data?.account_data;
              setLoadedScript(true);
              setModalParams({
                account_data: {
                  first_name: accountData.firstName,
                  last_name: accountData.lastName,
                  email: accountData.email,
                  phone: accountData.phoneNumber,
                  account_id: accountData.id,
                  sponsor: sponsor,
                  env: process.env.REACT_APP_ENV!,
                },
                care_options: content.careForOptions,
              });
            }
          }
        } else helperMethods.redirectToSharecareAuth(sponsor, offeringCode);
      }
    };

    postCode();
  }, [authorizationCode, offeringCode, sponsor]);

  useEffect(() => {
    if (statusScript === "enabled" && modalParams) {
      setIsLoading(false);
      window.triggerOnboardingModal(
        modalParams.account_data,
        null,
        null,
        null,
        modalParams.care_options,
        true,
        false
      );
    }
  }, [statusScript, modalParams]);

  return (
    <>
      {contentData && (
        <>
          <Header supportNumber={contentData!.supportNumber} />
          <Hero
            imgUrl={contentData!.heroImgURL}
            h1={contentData!.h1}
            blurb={contentData!.blurb}
            benefitText={contentData!.benefitText}
          />
        </>
      )}
      {(isLoading || errors) && (
        <ModalPortal isLoading={isLoading} errors={errors} />
      )}
    </>
  );
};

export default Home;
