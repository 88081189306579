import React from "react";
import "./Hero.css";

interface HeroProps {
  imgUrl: { web: string; mobile: string | null };
  h1: string;
  blurb: string;
  benefitText: string | null;
}

const Hero = ({ imgUrl, h1, blurb, benefitText }: HeroProps) => {
  return (
    <div className="hero sc-sharecare wf-section">
      <div className="sc_hero_container">
        <div className="sc_hero_message sharecare">
          <h1 className="sc_heading hr">{h1}</h1>
          <p className="sc-paragraph hr">{blurb}</p>
        </div>
        <div className="w-embed"></div>
        <div className="div-block-170">
          <img
            src={imgUrl.web}
            loading="lazy"
            srcSet={`${imgUrl.mobile || imgUrl.web} 500w, ${imgUrl.web} 705w`}
            sizes="(max-width: 479px) 90vw, (max-width: 991px) 50vw, 700px"
            alt=""
            className="image-127"
          />
        </div>
      </div>
      {benefitText && (
        <div className="sc_ribbon_wrap_sharecare hr">
          <div className="ribbon_sharecare hr">
            <p className="sc-paragraph ribbontext">{benefitText}</p>
          </div>
        </div>
      )}
    </div>
  );
};

export default Hero;
