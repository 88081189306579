const redirectToSharecareAuth = (
  sponsor?: string | null,
  offeringCode?: string | null
) => {
  const sharecareUrl = process.env.REACT_APP_SC_AUTH;
  const clientId = process.env.REACT_APP_SC_CLIENT_ID;
  const carelinxRedirectUri = process.env.REACT_APP_CL_INT;

  const params =
    sponsor || offeringCode
      ? `?${[
          offeringCode ? `offeringCode=${offeringCode}` : null,
          sponsor ? `sponsor=${sponsor}` : null,
        ]
          .filter((param) => param)
          .join("%26")}`
      : "";

  const authorizeUrl = `${sharecareUrl}oauth2/authorize?response_type=code&client_id=${clientId}&redirect_uri=${carelinxRedirectUri}${params}`;
  window.open(authorizeUrl, "_self");
};

const redirectToCarelinxDashboard = (carelinxUrl?: string | any) => {
  const carelinxDashUrl = process.env.REACT_APP_CL_DASH;

  carelinxUrl && window.open(`${carelinxDashUrl}${carelinxUrl}`, "_self");
};

export const helperMethods = {
  redirectToSharecareAuth,
  redirectToCarelinxDashboard,
};
