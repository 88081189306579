import { RefCodeSchema } from "../../types/content-manager";

export const contentManager: {
  [key: string]: {
    [key: string]: RefCodeSchema;
  };
} = {
  ER_GENERA: {
    SC_DGTL_CARELINX_IHC: {
      careForOptions: null,
      heroImgURL: {
        web: "https://assets-global.website-files.com/5df96de002010366cc55ebe8/614aab5336d8123d5fb077ba_sharecare-hero.png",
        mobile:
          "https://assets-global.website-files.com/5df96de002010366cc55ebe8/614aab5336d8123d5fb077ba_sharecare-hero-p-500.png",
      },
      supportNumber: "1-833-600-0812",
      h1: "Get high quality in home care for you and your loved ones",
      blurb:
        "Get high quality in home care for you and your loved ones Finding reliable in home care support is easy with CareLinx. Get access to medical and non-medical caregivers to help with activities of daily living such as: transportation , medication remindersm personal care and hygiene, light housekeeping, meal preparation, and companionship. The right caregiver for the support you need is waiting for you.",
      benefitText: null,
    },
    SC_DGTL_CARELINX_FIHC: {
      careForOptions: [
        {
          value: "5",
          label: "My wife",
          gender: "f",
          self: false,
        },
        {
          value: "6",
          label: "My husband",
          gender: "m",
          self: false,
        },
        {
          value: "7",
          label: "My female partner",
          gender: "f",
          self: false,
        },
        {
          value: "8",
          label: "My male partner",
          gender: "m",
          self: false,
        },
        {
          value: "9",
          label: "My daughter",
          gender: "f",
          self: false,
        },
        {
          value: "10",
          label: "My son",
          gender: "m",
          self: false,
        },
        {
          value: "14",
          label: "Myself (female)",
          gender: "f",
          self: true,
        },
        {
          value: "15",
          label: "Myself (male)",
          gender: "m",
          self: true,
        },
      ],
      heroImgURL: {
        web: "https://assets-global.website-files.com/5df96de002010366cc55ebe8/614aab5336d8123d5fb077ba_sharecare-hero.png",
        mobile:
          "https://assets-global.website-files.com/5df96de002010366cc55ebe8/614aab5336d8123d5fb077ba_sharecare-hero-p-500.png",
      },
      supportNumber: "1-833-600-0812",
      h1: "Get high quality in home care for you and your loved ones",
      blurb:
        "Get high quality in home care for you and your loved ones Finding reliable in home care support is easy with CareLinx. Get access to medical and non-medical caregivers to help with activities of daily living such as: transportation , medication remindersm personal care and hygiene, light housekeeping, meal preparation, and companionship. The right caregiver for the support you need is waiting for you.",
      benefitText: null,
    },
  },
  ER_LENNAR: {
    SC_DGTL_CARELINX_IHC: {
      careForOptions: null,
      heroImgURL: {
        web: "https://assets-global.website-files.com/5df96de002010366cc55ebe8/614aab5336d8123d5fb077ba_sharecare-hero.png",
        mobile:
          "https://assets-global.website-files.com/5df96de002010366cc55ebe8/614aab5336d8123d5fb077ba_sharecare-hero-p-500.png",
      },
      supportNumber: "1-833-600-0812",
      h1: "Get high quality in home care for you and your loved ones",
      blurb:
        "Get high quality in home care for you and your loved ones Finding reliable in home care support is easy with CareLinx. Get access to medical and non-medical caregivers to help with activities of daily living such as: transportation , medication remindersm personal care and hygiene, light housekeeping, meal preparation, and companionship. The right caregiver for the support you need is waiting for you.",
      benefitText: null,
    },
    SC_DGTL_CARELINX_FIHC: {
      careForOptions: [
        {
          value: "5",
          label: "My wife",
          gender: "f",
          self: false,
        },
        {
          value: "6",
          label: "My husband",
          gender: "m",
          self: false,
        },
        {
          value: "7",
          label: "My female partner",
          gender: "f",
          self: false,
        },
        {
          value: "8",
          label: "My male partner",
          gender: "m",
          self: false,
        },
        {
          value: "9",
          label: "My daughter",
          gender: "f",
          self: false,
        },
        {
          value: "10",
          label: "My son",
          gender: "m",
          self: false,
        },
        {
          value: "14",
          label: "Myself (female)",
          gender: "f",
          self: true,
        },
        {
          value: "15",
          label: "Myself (male)",
          gender: "m",
          self: true,
        },
      ],
      heroImgURL: {
        web: "https://assets-global.website-files.com/5df96de002010366cc55ebe8/614aab5336d8123d5fb077ba_sharecare-hero.png",
        mobile:
          "https://assets-global.website-files.com/5df96de002010366cc55ebe8/614aab5336d8123d5fb077ba_sharecare-hero-p-500.png",
      },
      supportNumber: "1-833-600-0812",
      h1: "Get high quality in home care for you and your loved ones",
      blurb:
        "Get high quality in home care for you and your loved ones Finding reliable in home care support is easy with CareLinx. Get access to medical and non-medical caregivers to help with activities of daily living such as: transportation , medication remindersm personal care and hygiene, light housekeeping, meal preparation, and companionship. The right caregiver for the support you need is waiting for you.",
      benefitText: null,
    },
  },
  ER_SHARECARE: {
    SC_DGTL_CARELINX_FIHC: {
      careForOptions: [
        {
          value: "5",
          label: "My wife",
          gender: "f",
          self: false,
        },
        {
          value: "6",
          label: "My husband",
          gender: "m",
          self: false,
        },
        {
          value: "7",
          label: "My female partner",
          gender: "f",
          self: false,
        },
        {
          value: "8",
          label: "My male partner",
          gender: "m",
          self: false,
        },
        {
          value: "9",
          label: "My daughter",
          gender: "f",
          self: false,
        },
        {
          value: "10",
          label: "My son",
          gender: "m",
          self: false,
        },
        {
          value: "14",
          label: "Myself (female)",
          gender: "f",
          self: true,
        },
        {
          value: "15",
          label: "Myself (male)",
          gender: "m",
          self: true,
        },
      ],
      heroImgURL: {
        web: "https://assets-global.website-files.com/5df96de002010366cc55ebe8/614aab5336d8123d5fb077ba_sharecare-hero.png",
        mobile:
          "https://assets-global.website-files.com/5df96de002010366cc55ebe8/614aab5336d8123d5fb077ba_sharecare-hero-p-500.png",
      },
      supportNumber: "1-888-546-4202",
      h1: "Get high quality in home care for you and your loved ones",
      blurb:
        "Get high quality in home care for you and your loved ones. Finding reliable in home care support is easy with CareLinx. Get access to medical and non-medical caregivers to help with activities of daily living such as: transportation , medication reminders, personal care and hygiene, meal preparation and more. The right caregiver for the support you need is waiting for you.",
      benefitText: "4 free hours of in home care +15% discount fee",
    },
  },
};
