import React from "react";
import "./Header.css";

interface HeaderProps {
  supportNumber: string;
}

const Header = ({ supportNumber }: HeaderProps) => {
  return (
    <div className="sc-header">
      <div className="div-block-169">
        <div className="header_uch_l">
          <img
            src="https://assets-global.website-files.com/5df96de002010366cc55ebe8/614aa736e9f8dbc878b9ff10_logo_sc_clx.svg"
            loading="lazy"
            alt=""
          />
        </div>
        <div className="sc-header-r">
          <p className="sc-paragraph header">
            Have Questions? Call today {supportNumber}
          </p>
        </div>
      </div>
    </div>
  );
};

export default Header;
